"use client";

import React from "react";
import Image from "next/image";
import useMediaQueryState from "@/hooks/useMediaQueryState";
import { useTheme } from "@mui/material";
import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import {
  Carousel,
  colors,
  Link,
  useIntersectionObserver,
} from "@/elements/src";
import { styled } from "@mui/material/styles";
import {
  ADVISORS_TITLE,
  ART_BY_CAPTION,
  ASTRONAUTS_TITLE,
  ASTROS_URLS,
  AWKWARDVERSE_CAPTION_1,
  AWKWARDVERSE_CAPTION_2,
  AWKWARDVERSE_TITLE,
  AWKWARD_ORIGIN,
  AWKWARD_TITLE,
  BUY_ASTRONAUT_TEXT,
  COMMUNITY_DESC,
  COMMUNITY_TITLE,
  CORE_TEAM_TITLE,
  FAQ,
  JOIN_DISCORD,
  LAMBO_NAME,
  LEARN_MORE_SOON,
  SPACEMAP_2,
  SPACEMAP_DESC,
  SPACEMAP_DISCLAIMER,
  STORY_PARAGRAPH_1,
  STORY_PARAGRAPH_2,
  STORY_TITLE,
  TEAM_TITLE,
  advisorItems,
  cloudinaryAssets,
  communityItems,
  coreTeamItems,
  faqItems,
  roadmapItems,
} from "./constants";

const AstroSprites = styled(Box)(({ theme }) => ({
  "@keyframes orbit": {
    from: {
      backgroundPositionX: "0",
    },
    to: {
      backgroundPositionX: "-9999px",
    },
  },
  animation: "orbit infinite",
  animationDuration: "300s",
  background: `url(${cloudinaryAssets.astroSprites})`,
  backgroundSize: "auto 100%",
  [theme.breakpoints.down("sm")]: {
    animationDuration: "200s",
  },
  [theme.breakpoints.down("xs")]: {
    animationDuration: "1000s",
  },
}));

const OriginStories = styled(Box, {
  shouldForwardProp: (prop) => prop !== "mobile",
  // @ts-ignore
})(({ mobile, theme }) => ({
  background: `url(${
    mobile ? cloudinaryAssets.originsMobile : cloudinaryAssets.originsDesktop
  })`,
  backgroundSize: "cover",
}));

const CommunitySprites = styled(Box)(({ theme }) => ({
  background: `url(${cloudinaryAssets.communitySprites})`,
  backgroundSize: "cover",
}));

const AwkwardAstronauts = () => {
  const theme = useTheme();
  const isMobile = useMediaQueryState(theme.breakpoints.down("md"));

  const [imgRef, isVisible] = useIntersectionObserver({
    opts: {
      threshold: 0.75,
    },
  });
  const hiddenLineupTop = isMobile ? "64px" : "160px";
  const titleSx = { fontSize: { xs: "24px", md: "40px" }, textAlign: "center" };
  const bodySx = { fontSize: { xs: "12px", md: "14px" }, textAlign: "center" };

  return (
    <>
      <Box sx={{paddingTop: isMobile ? "40px" : "120px"}}>
        <Container
        maxWidth= "xl"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: "32px", md: "96px" } }}
          >
            {AWKWARD_TITLE}
          </Typography>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: "32px", md: "96px" } }}
          >
            {ASTRONAUTS_TITLE}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "16px", md: "24px" },
              textAlign: "center",
              marginTop: isMobile ? "16px" : "24px",
              marginBottom: isMobile ? "16px" : "24px",
            }}
          >
            {ART_BY_CAPTION}
            <Link href={ASTROS_URLS.LAMBO}>
              <Typography
                color="primary"
                variant="h4"
                component="span"
                sx={{
                  fontSize: { xs: "16px", md: "24px" },
                  marginLeft: isMobile ? "4px" : "8px",
                  textDecoration: "underline",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                {LAMBO_NAME}
              </Typography>
            </Link>
            .
          </Typography>
          <Link href={ASTROS_URLS.MARKETPLACE} underline={false}>
            <Button
              variant="outlined"
              sx={{
                borderRadius: "10px",
                borderColor: "white",
                "&:hover": {
                  backgroundColor: colors.niftyWhite,
                  color: "navy",
                },
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: isMobile ? "16px" : "20px",
                  fontWeight: "800",
                  marginY: isMobile ? "12px" : "18px",
                  marginX: isMobile ? "40px" : "24px",
                }}
              >
                {BUY_ASTRONAUT_TEXT}
              </Typography>
            </Button>
          </Link>
        </Container>
        <Box
          sx={{
            height: isMobile ? "128px" : "320px",
            overflow: "hidden",
            position: "relative",
            width: "100%",
            marginTop: isMobile ? "8px" : "0px",
          }}
          ref={imgRef}
        >
          <picture>
            <img
              src={cloudinaryAssets.astronautLineup}
              style={{
                height: isMobile ? "128px" : "320px",
                top: isVisible ? "0px" : hiddenLineupTop,
                transition: "top 1.5s ease",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
              }}
              alt="Astronaut lineup"
            />
          </picture>
        </Box>
        <AstroSprites
          marginTop="0px"
          height={isMobile ? "40px" : "80px"}
          width="100%"
        />
        <Container
        maxWidth= "lg"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              marginTop: isMobile ? "120px" : "160px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography variant="h2" sx={titleSx}>
                {STORY_TITLE}
              </Typography>
              <Typography mt="16px" sx={bodySx}>
                {STORY_PARAGRAPH_1}
              </Typography>
              <Typography mt={isMobile ? "12px" : "24px"} sx={bodySx}>
                {STORY_PARAGRAPH_2}
              </Typography>
            </Box>
          </Box>
          <OriginStories
            sx={{
              marginY: "32px",
              height: isMobile ? "188px" : "220px",
              maxWidth: isMobile ? "340px" : "800px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "10px",
            }}
            // @ts-ignore
            mobile={isMobile}
          >
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: { xs: "18px", md: "24px" },
                marginTop: isMobile ? "24px" : "36px",
              }}
            >
              {AWKWARDVERSE_TITLE}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "75%",
                flexDirection: "row",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: "12px", md: "14px" },
                  textAlign: "center",
                  marginTop: isMobile ? "4px" : "8px",
                }}
              >
                {AWKWARDVERSE_CAPTION_1}
                <Link href={ASTROS_URLS.LAMBO}>
                  <Typography
                    color="primary"
                    variant="body1"
                    component="span"
                    sx={{
                      fontSize: { xs: "12px", md: "14px" },
                      marginLeft: isMobile ? "2px" : "4px",
                      marginRight: isMobile ? "2px" : "4px",
                      textDecoration: "underline",
                    }}
                  >
                    {LAMBO_NAME}
                  </Typography>
                </Link>
                {AWKWARDVERSE_CAPTION_2}
                <Link href={ASTROS_URLS.AWKWARD_ORIGINS}>
                  <Typography
                    color="primary"
                    variant="body1"
                    component="span"
                    sx={{
                      fontSize: { xs: "12px", md: "14px" },
                      marginLeft: isMobile ? "2px" : "4px",
                      textDecoration: "underline",
                    }}
                  >
                    {AWKWARD_ORIGIN}
                  </Typography>
                </Link>
                .
              </Typography>
            </Box>
            <Button
              variant="contained"
              disabled
              sx={{
                borderRadius: "100px",
                borderColor: "white",
                marginTop: isMobile ? "12px" : "16px",
                paddingX: "16px",
                paddingY: "8px",
                background: "rgba(1, 3, 4, 0.08)",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "#999A9B",
                }}
              >
                {LEARN_MORE_SOON}
              </Typography>
            </Button>
          </OriginStories>
          <Box
            sx={{
              marginTop: isMobile ? "120px" : "160px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography variant="h2" sx={titleSx}>
                {SPACEMAP_2}
              </Typography>
              <Typography sx={{ ...bodySx, marginTop: "16px" }}>
                {SPACEMAP_DESC}
              </Typography>
              <Typography
                sx={{
                  ...bodySx,
                  fontStyle: "italic",
                  marginTop: isMobile ? "12px" : "24px",
                }}
              >
                {SPACEMAP_DISCLAIMER}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: "32px",
              display: "flex",
              alignItems: "flex-start",
              flexDirection: isMobile ? "column" : "row",
              width: isMobile ? "95%" : "70%",
              marginLeft: isMobile ? "0%" : "4%",
            }}
          >
            <Box
              sx={{
                position: "relative",
                marginLeft: isMobile ? "0" : "160px",
                marginTop: isMobile ? "24px" : "0",
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "48px",
                    md: "64px",
                  },
                  left: "0",
                  position: "absolute",
                  top: "8px",
                }}
              >
                🪐
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  padding: isMobile ? "76px 0 0 22px" : "100px 0 0 29px",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "4px",
                    borderWidth: "2px",
                    background: "#E0DFB2",
                    width: "4px",
                  }}
                />
                <Box sx={{ marginLeft: "40px" }}>
                  {roadmapItems.map(
                    ({
                      buttonData,
                      date,
                      isNext,
                      isComplete,
                      title,
                      bullets,
                    }) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          position: "relative",
                          marginTop: isMobile ? "24px" : "40px",
                        }}
                        key={title}
                      >
                        <Box
                          sx={{
                            bgcolor: isComplete
                              ? "white"
                              : "background.default",
                            position: "absolute",
                            top: isMobile ? "6px" : "8px",
                            left: isMobile ? "-47px" : "-50px",
                            border: "3px solid white",
                            height: isMobile ? "12px" : "16px",
                            width: isMobile ? "12px" : "16px",
                            borderRadius: "50%",
                          }}
                        />
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          sx={{
                            fontSize: isMobile ? "12px" : "14px",
                            opacity: isNext ? 0.6 : 1,
                          }}
                        >
                          {date}
                        </Typography>
                        <Typography
                          variant="h3"
                          color="text.primary"
                          sx={{
                            fontSize: isMobile ? "20px" : "24px",
                            opacity: isNext ? 0.6 : 1,
                          }}
                        >
                          {title}
                        </Typography>
                        {bullets && (
                          <List sx={{ listStyleType: "disc", pl: 2 }}>
                            {bullets.map((value) => (
                              <ListItem
                                key={value}
                                sx={{
                                  display: "list-item",
                                  margin: 0,
                                  padding: 0,
                                  fontVariant: "body1",
                                  fontWeight: "700",
                                  fontSize: isMobile ? "12px" : "16px",
                                }}
                              >
                                {value}
                              </ListItem>
                            ))}
                          </List>
                        )}
                        {buttonData && (
                          <Link
                            openInNewTab
                            href={buttonData.href}
                            underline={false}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              gap={2}
                              borderRadius={1}
                              py={2}
                              px={3}
                              mt={2}
                              sx={
                                buttonData.alt === "Space Station"
                                  ? {
                                      backgroundColor: "transparent",
                                      border: `2px solid ${colors.niftyWhite}`,
                                      width: "360px",
                                    }
                                  : { backgroundColor: colors.niftyWhite }
                              }
                            >
                              {buttonData.image && (
                                <Image
                                  height={32}
                                  width={32}
                                  src={buttonData.image}
                                  alt={buttonData.alt}
                                />
                              )}
                              <Typography
                                variant="h6"
                                component="span"
                                color={colors.niftyBlack}
                                fontWeight={700}
                                fontSize={isMobile ? 14 : 16}
                              >
                                {buttonData.text}
                              </Typography>
                            </Box>
                          </Link>
                        )}
                      </Box>
                    )
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            mt={isMobile ? "120px" : "160px"}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="h2" sx={titleSx}>
              {TEAM_TITLE}
            </Typography>
            <Typography
              mt={isMobile ? "32px" : "48px"}
              variant="h2"
              sx={{ fontSize: { xs: "16px", md: "24px" } }}
            >
              {CORE_TEAM_TITLE}
            </Typography>

            <Box
              display="flex"
              gap="24px"
              flexWrap="wrap"
              mt="24px"
              justifyContent="center"
            >
              {coreTeamItems.map(({ name, thumbnail, role, link }) => (
                <Link href={link} key={name}>
                  <Box display="flex" flexDirection="column">
                    <Box
                      sx={{
                        borderRadius: "10px",
                        overflow: "hidden",
                        height: isMobile ? "152px" : "180px",
                        width: isMobile ? "152px" : "180px",
                      }}
                    >
                      <picture>
                        <img
                          width={isMobile ? "152px" : "180px"}
                          src={thumbnail}
                          alt={name}
                        />&nbsp;
                      </picture>
                    </Box>

                    <Typography
                      variant="caption"
                      mt="8px"
                      sx={{ fontWeight: 700, color: "white" }}
                    >
                      {name}
                    </Typography>
                    <Typography
                      mt="2px"
                      sx={{
                        fontSize: "12px",
                        textAlign: "left",
                        color: "white",
                      }}
                    >
                      {role}
                    </Typography>
                  </Box>
                </Link>
              ))}
            </Box>

            <Typography
              mt={isMobile ? "32px" : "48px"}
              variant="h2"
              sx={{ fontSize: { xs: "16px", md: "24px" } }}
            >
              {ADVISORS_TITLE}
            </Typography>

            <Box
              display="flex"
              gap="24px"
              flexWrap="wrap"
              mt="24px"
              justifyContent="center"
            >
              {advisorItems.map(({ name, thumbnail, role, link }) => (
                <Link href={link} key={name}>
                  <Box display="flex" flexDirection="column">
                    <Box
                      sx={{
                        borderRadius: "10px",
                        overflow: "hidden",
                        height: isMobile ? "152px" : "180px",
                        width: isMobile ? "152px" : "180px",
                      }}
                    >
                      <picture>
                        <img
                          width={isMobile ? "152px" : "180px"}
                          src={thumbnail}
                          alt={name}
                        />&nbsp;
                      </picture>
                    </Box>
                    <Typography
                      variant="caption"
                      sx={{ fontWeight: 700, color: "white", marginTop: "8px" }}
                    >
                      {name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        textAlign: "left",
                        color: "white",
                        marginTop: "2px",
                      }}
                    >
                      {role}
                    </Typography>
                  </Box>
                </Link>
              ))}
            </Box>
          </Box>
        </Container>
        <CommunitySprites
          mt={isMobile ? "60px" : "200px"}
          display="flex"
          flexDirection="column"
          alignItems="center"
          py={isMobile ? "80px" : "120px"}
        >
          <Container
          maxWidth= "lg"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                ...titleSx,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                variant: "h2",
              }}
            >
              {COMMUNITY_TITLE}&nbsp;
            </Typography>
            <Typography
              sx={{
                ...bodySx,
                marginTop: "24px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {COMMUNITY_DESC}
            </Typography>
            <Link href={ASTROS_URLS.DISCORD}>
              <Button
                variant="contained"
                sx={{
                  display: "inline-block",
                  marginTop: isMobile ? "16px" : "24px",
                  paddingY: isMobile ? "8px" : "10px",
                  paddingX: isMobile ? "16px" : "24px",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.tertiary",
                    fontSize: { xs: "12px", md: "16px" },
                  }}
                >
                  {JOIN_DISCORD}
                </Typography>
              </Button>
            </Link>
          </Container>

          <Box
            sx={{ marginTop: isMobile ? "32px" : "48px", width: "100%" }}
          >
            <Carousel
              headline="Created by the community:"
              smallHeadline
              smallHeadlineVariant={isMobile ? "h5" : "h4"}
              hidePaginationButtons
              subheadlineColor="primary"
              items={communityItems}
              assetHeight={isMobile ? 184 : 330}
            >
              {({ item, index }) => (
                <Link href={item.href}>
                  <Box
                    sx={{
                      height: isMobile ? "184px" : "330px",
                      borderRadius: "10px",
                      width: isMobile ? "152px" : "282px",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <picture>
                      <img
                        width="100%"
                        height={isMobile ? "152px" : "282px"}
                        src={item.thumbnail}
                        alt={item.label}
                      />
                    </picture>
                    <Box
                      sx={{
                        height: isMobile ? "32px" : "48px",
                        width: "100%",
                        padding: isMobile ? "8px" : "12px",
                        backgroundColor: "white",
                        margin: 0,
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: "text.tertiary",
                          fontSize: isMobile ? "12px" : "16px",
                        }}
                      >
                        {item.label}
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              )}
            </Carousel>
          </Box>
        </CommunitySprites>
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginTop: isMobile ? "60px" : "200px",
              paddingBottom: isMobile ? "60px" : "200px",
            }}
          >
            <Typography variant="h2" sx={titleSx}>
              {FAQ}
            </Typography>
            <Box>
              {faqItems.map(({ answer, question, link }) => (
                <Box sx={{ marginTop: "32px" }} key={question}>
                  <Typography sx={{ fontWeight: 700 }}>{question}</Typography>
                  <Typography
                    color="primary"
                    sx={{
                      fontSize: { xs: "14px", md: "18px" },
                      marginTop: "8px",
                    }}
                  >
                    {answer.split("{{link}}")[0]}
                    {link && (
                      <a href={link.href} target="_blank" rel="noreferrer">
                        <Typography
                          color="primary"
                          component="span"
                          sx={{
                            fontSize: { xs: "14px", md: "18px" },
                            textDecoration: "underline",
                          }}
                        >
                          {link.label}
                        </Typography>
                      </a>
                    )}
                    {answer.split("{{link}}")[1]}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AwkwardAstronauts;
